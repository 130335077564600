<template>
  <v-container fluid>
    <v-data-iterator
      :items="filteredSearchResults"
      :search="currentRefinement"
      :page.sync="currentPage"
      hide-default-footer
      no-data-text=""
      no-results-text=""
    >
      <template v-slot:header>
        <v-container>
          <v-card style="border-width: 0;" elevation="0" :class="['rounded-xxl', 'rounded-t-0', cardClasses, { 'dark-card': darkTheme }]" :dark="darkTheme">
            <v-card-title class="text-center justify-center py-6">
              <h1 class="text-h5"><strong>Search Result</strong></h1>
            </v-card-title>
            <v-card-text>
              <v-row justify="center" align="center">
                <v-col cols="12" md="8">
                  <v-text-field
                    ref="searchField"
                    prepend-inner-icon="search"
                    type="search"
                    v-model="currentRefinement"
                    placeholder="Search Cases"
                    @input="handleInput"
                    solo
                    outlined
                    clearable
                    rounded
                    color="darkTheme"
                    hide-details="auto"
                    :class="{ 'v-input--dense': !$vuetify.breakpoint.mdAndUp }"
                  />
                </v-col>
                <v-col cols="6" md="2" class="d-flex justify-end justify-md-center align-center">
                  <v-btn rounded outlined color="primary" block :to="'/explore-cases'">
                    <v-icon left>mdi-briefcase-variant-outline</v-icon>All Cases
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-expand-transition>
              <div v-show="show">
                <v-card :class="darkTheme ? 'card-expand-dark' : 'card-expand'" style="border-width: 0;">
                  <v-card-text class="text-center">
                    <em>Search result for "{{ currentRefinement }}"</em>
                  </v-card-text>
                </v-card>
              </div>
            </v-expand-transition>
          </v-card>
        </v-container>
      </template>

      <template v-slot:default>
        <v-row class="flex-grow-1">
          <v-col cols="12">
            <v-container style="min-height: 30em;">
              <v-list style="display: flex; flex-direction: column; align-items: center; background-color: transparent;">
                <v-list-item-group v-for="item in paginatedResults" :key="item.refno">
                  <router-link :to="item.objectID ? { name: 'case', params: { id: item.objectID } } : '#'" style="text-decoration: none;">
  
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-list-item justify class="px-0" style="max-width: 800px;">
                          <v-card class="rounded-xl pa-3" :style="borderStyle">
                            <v-img
                              v-if="faviconImage || lightImage"
                              alt="Protected"
                              :src="logoImage"
                              contain
                              cover
                              no-repeat
                              position="right"
                              :style="{
                                opacity: 0.3,
                                position: 'absolute',
                                top: '50%',
                                right: '2%',
                                transform: 'translateY(-50%)',
                                zIndex: 0,
                                maxHeight: '160px',
                              }"
                            ></v-img>
                            
                            <v-row align="center" class="mx-2">
                              <v-chip
                                label
                                small
                                color="secondary"
                                class="text-overline ml-2 my-2"
                              >
                                {{ item.refno }}
                              </v-chip>
                              <v-chip
                                label
                                small
                                color="primary"
                                class="text-overline ml-2"
                                v-show="!!item.idx"
                                v-text="item.idx"
                              >
                              </v-chip>
                              <v-spacer></v-spacer> <!-- This pushes the date to the right -->
                              <v-icon class="mr-1">mdi-calendar-outline</v-icon>
                              <span class="caption mr-3">{{ showDate(item.date) }}</span>
                            </v-row>


                            <v-card-title>
                              {{ item.short }}
                            </v-card-title>
                            
                            <blockquote class="text-subtitle-2 blockquote mr-2">
                              "{{ item.syllabus[0] }}"
                            </blockquote>
                          </v-card>
                          <v-fade-transition>
                            <v-overlay
                              v-if="hover"
                              absolute
                              color="#c9c9c9"
                              class="rounded-xl"
                              style="z-index: 3;"
                            >
                              <v-btn rounded small color="primary">view more</v-btn>
                            </v-overlay>
                          </v-fade-transition>
                        </v-list-item>
                      </template>
                    </v-hover>
                    
                  </router-link>
                  <div style="margin-bottom: 15px;"></div>
                </v-list-item-group>
              </v-list>
            </v-container>
          </v-col>
        </v-row>
      </template>

      <template v-slot:no-data>
        <v-empty-state
          icon="mdi-alert-circle-outline"
          title="No cases found"
          description="Try adjusting your search to find what you are looking for."
        />
      </template>

      <template v-slot:no-results>
        <v-empty-state
          icon="mdi-alert-circle-outline"
          title="No matching cases found"
          description="Try a different search term."
        />
      </template>

      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="center">
          <v-col cols="12" md="8" style="position: fixed; bottom: 0; margin-bottom: 2.5rem; width: 100%; z-index: 1000;">
            <v-row align="center" justify="center">
              <v-pagination v-model="currentPage" :length="numberOfPages" :total-visible="5" circle color="primary"></v-pagination>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import algoliasearch from 'algoliasearch/lite';
import moment from 'moment';
import useLocalStorage from '@/composables/useLocalStorage';
import VEmptyState from '@/components/VEmptyState.vue';
import Fuse from 'fuse.js';

export default {
  components: {
    VEmptyState,
  },
  data() {
    return {
      pageLoaded: false,
      searchClient: algoliasearch(
        "4Z44T2TZTE",
        "afd9d28e0bc4c31b52e8dc5709077153"
      ),
      searchResults: [],
      currentPage: 1,
      itemsPerPage: 10,
      faviconImage: null,
      lightImage: null,
      show: false,
      isFixed: false,
      offlineHits: [],
      isOnline: navigator.onLine,  // Ensure correct initialization of online status
      overlay: false,
      stickyThreshold: 300, 
      selectedTier: "",  // Change this based on user roles
      tiers: ["Tier1", "Tier2", "Tier3", "Tier4"],  // Tier filtering
    };
  },
  created() {
    const faviconImageStorage = useLocalStorage(null, "faviconImage");
    const lightImageStorage = useLocalStorage(null, "lightImage");
    this.faviconImage = faviconImageStorage.get();
    this. lightImage = lightImageStorage.get();
    this.setSelectedTier();
    const storedOfflineHits = localStorage.getItem('offlineHits');
    if (storedOfflineHits) {
      this.offlineHits = JSON.parse(storedOfflineHits);
    }
  },

  computed: {
    ...mapState(['myValue', 'userProfile']),
    ...mapGetters(['darkTheme']),

    cardClasses() {
      return {
        'fixed-card': this.isFixed,
        'relative-card': !this.isFixed,
      };
    },
    borderStyle() {
      return {
        border: `0.5px solid ${this.$vuetify.theme.themes.dark.secondary}`,
      };
    },
    currentRefinement: {
      get() {
        return this.myValue || '';
      },
      set(value) {
        this.$store.commit('setValue', value);
      },
    },
    processedSearchResults() {
      return this.searchResults.map((item) => ({
        refno: item.refno,
        short: item.short,
        idx: item.idx !== null && item.idx !== undefined ? item.idx : '',
        date: item.date,
        objectID: item.objectID,
        syllabus: item.syllabus,
        tiers: item.tiers,
      }));
    },
    filteredSearchResults() {
      let results = [];

      if (this.isOnline) {
        results = this.processedSearchResults;
      } else {
        results = this.filteredOfflineSearchResults;
      }

      if (this.selectedTier) {
        results = results.filter(item => item.tiers.includes(this.selectedTier));
      }

      return results;
    },

    paginatedResults() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredSearchResults.slice(startIndex, endIndex);
    },

    numberOfPages() {
      return Math.ceil(this.filteredSearchResults.length / this.itemsPerPage);
    },

    filteredOfflineSearchResults() {
      console.log("Searching offline...");
      if (this.currentRefinement.trim() === "") return [];

      const fuse = new Fuse(this.offlineHits, {
        keys: [
          { name: 'refno', weight: 0.5 },
          { name: 'short', weight: 0.3 },
          { name: 'references[0].refno', weight: 0.5 },
          { name: 'references[0].short', weight: 0.3 },
          { name: 'references[0].title', weight: 0.2 },
        ],
        threshold: 0.3,
        distance: 100,
      });

      const results = fuse.search(this.currentRefinement.trim());

      console.log("Results:", results);

      return results.map(result => result.item);
    },
    logoImage() {
      return this.$vuetify.theme.dark ? this.lightImage : this.faviconImage;
    },
  },
  methods: {
    updateOnlineStatus() {
      this.isOnline = navigator.onLine;
    },

    setSelectedTier() {
      if (this.userProfile && this.userProfile.role) {
        switch (this.userProfile.role) {
          case "admin":
            this.selectedTier = "Tier4";
            break;
          case "contributor":
            this.selectedTier = "Tier4";
            break;
          case "Tier1":
            this.selectedTier = "Tier1";
            break;
          case "Tier2":
            this.selectedTier = "Tier2";
            break;
          case "Tier3":
            this.selectedTier = "Tier3";
            break;
          case "Tier4":
            this.selectedTier = "Tier4";
            break;

          default:
            this.selectedTier = "Tier4";
        }
      } else {
        this.selectedTier = "Tier4";
      }
    },

    handleInput(value) {
      const trimmedValue = value ? value.trim() : '';
      this.$store.commit('setValue', trimmedValue);

      if (trimmedValue !== '') {
        this.updateSearchResults();
        this.show = true;
      } else {
        this.searchResults = [];
        this.show = false;
      }
    },
    handleScroll() {
      const scrollTop = window.scrollY;
      // Switch between fixed and relative positions based on scroll position
      this.isFixed = scrollTop > this.stickyThreshold;
    },
    showDate(v) {
      return moment.unix(v).format('LL');
    },
    async updateSearchResults() {
      if (this.currentRefinement.length > 0) {
        const { hits } = await this.searchClient
          .initIndex('cases_prod')
          .search(this.currentRefinement, {
            hitsPerPage: 50,
          });
        this.searchResults = hits.map((hit) => ({
          refno: hit.references[0].refno,
          short: hit.references[0].short,
          idx: hit.idx || null,
          date: hit.date,
          objectID: hit.objectID,
          syllabus: hit.syllabus,
          tiers: hit.tiers,
        }));
      } else {
        this.searchResults = [];
      }

      this.currentPage = 1;
    },
    updateReceivedValue(value) {
      this.currentRefinement = value;
      this.updateSearchResults();
    },
    updateCurrentPage(newPage) {
      this.currentPage = newPage;
    },
    focusOnTextField() {
      if (this.$refs.searchField) {
        this.$refs.searchField.focus();
      }
    },
  },
  mounted() {
    if (!this.pageLoaded) {
      this.pageLoaded = true;
      this.focusOnTextField();
      if (this.currentRefinement) {
        this.updateSearchResults();
      }
    }
    // Calculate sticky threshold on mount
    this.$nextTick(() => {
      const component = this.$refs.searchComponent;
      if (component) {
        const componentTop = component.getBoundingClientRect().top + window.scrollY;
        this.stickyThreshold = componentTop;
      }
    });
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll); 
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
};
</script>


<style scoped>
.fixed-card {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 85%;
  z-index: 4;
  background: linear-gradient(180deg, #fff, #c9c9c9);
}

.relative-card {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(180deg, #fff, #c9c9c9);
}

.dark-card {
  background: linear-gradient(180deg, #111111, #666);
  border-width: 0;
}

.card-expand {
  background-color: #D8D8D8;
}

.card-expand-dark {
  background-color: #484848;
}
</style>